//change data section before save
const filterData = (data, type) => {
    let copyData = data;

    if(type == "iconDescData" || type == "galleryMT") {
        let res = copyData && copyData.map(item => {
            if(item.image) {
                item.image = item.image.id;
            }
            return item;
        })
        copyData = res;
    }else if(type == "filesDownload") {
        let res = copyData && copyData.map(item => {
            if(item.file) {
                item.file = item.file.id;
            }
            // item.category = item.category.map(cat => {
            //     cat = cat.id;
            //     return cat;
            // })
            if(item.category && item.category[0]?.id) {
                item.category = item.category[0].id;
            }else{
                item.category = item.category;
            }
            return item;
        })
        copyData = res;
    }else if(type == "colorOfProducts" || type == "colorOfTechnicalDesc") {
        let res = copyData && copyData.map(item => {
            item = item.id
            return item;
        })
        copyData = res;
    }

    return copyData;
}

export default filterData;