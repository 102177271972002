import React, { useState, useEffect, useRef } from 'react'
import Input from '../../../../components/input/Input'
import Textarea from '../../../../components/input/Textarea';
import axios from 'axios';
import ApiUrl from '../../../../services/ApiUrl';
import Sections from '../../../../components/sections/Sections';
import { useSelector, useDispatch } from 'react-redux'
import Get from '../../../../services/Get';
import Select from '../../../../components/select/Select';
import Categories from './../../../../components/boxes/Categories';
import filterSectionsData from './../../../../services/FilterSectionsData';
import DateTimeRangerPicker from '../../../../components/boxes/DateTimeRangerPicker';
import ImagePost from './../../../../components/boxes/ImagePost';
import StatusPage from './../../../../components/boxes/StatusPage';
import PageTitle from './../../../../components/page-title/PageTitle';
import Button from './../../../../components/button/Button';
import readTextNvda from './../../../../services/readTextNvda';
import SaveIcon from './../../../../svg-components/SaveIcon';
import Editor from './../../../../components/editor/Editor';
import MediaLoad from './../../../../components/media-load/MediaLoad';
import SelectItems from './../../../../components/select-items/SelectItems';
import Gallery from './../../../../components/gallery/Gallery';
import IconDesc from './../../../../components/icon-desc/IconDesc';
import filterData from './../../../../services/filterData';
import FilesDownload from './../../../../components/files-download/FilesDownload';
import FilterRelationSections from './../../../../services/FilterRelationSections';

const NewPage = (props) => {
    let [ load, setLoad ] = useState(false)
    let [ blockedBtn, setBlockedBtn ] = useState(false);
    let [ title, setTitle ] = useState('');
    let [ sneakpeak, setSneakpeak ] = useState('');
    let [ disableTopDate, setDisableTopDate ] = useState(false);
    let [ important, setImportant ] = useState(false);
    let [ status, setStatus ] = useState("published");
    let [ sections, setSections ] = useState([])
    let [ resetLoad, setResetLoad ] = useState(0);
    let [ validateSections, setValidateSections ] = useState(0);
    let [ btnClickedNumber, setBtnClickedNumber ] = useState(0);
    let language = useSelector(state => state.lang);
    let [ langID, setLangID ] = useState();
    let categories = useSelector(state => state.categories);
    const dispatch = useDispatch();
    let [ resultPage, setResultPage ] = useState();
    let postType = typeof window !== "undefined" && (window.location.pathname).split("/");postType = postType[2]
    let countData = useSelector(state => state.countData);
    let [ dateRange, setDateRange ] = useState([])
    let [ previewImage, setPreviewImage ] = useState(false);
    let [ categoriesArray, setCategoriesArray ] = useState([]);
    let [ seoDescription, setSeoDescription ] = useState('');
    let [ forceValidate, setForceValidate ] = useState(false);
    let [ lastClickedRadioCategory, setLastClickedRadioCategory ] = useState(false); 
    let [ dimensions, setDimensions ] = useState('');
    let [ line, setLine] = useState("");
    let [ width, setWidth ] = useState("");
    let [ height, setHeight ] = useState("");
    let [ depth, setDepth ] = useState("");
    let [ length, setLength ] = useState();
    let [ coloursTechnicalDesc, setColoursTechnicalDesc ] = useState([]);
    let [ technicalDescription, setTechnicalDescription ] = useState("");
    let [ collectionDesc, setCollectionDesc ] = useState("");
    let [ TD, setTD ] = useState(false); 
    let [ brandsList, setBrandsList ] = useState(false);
    let [ chooseTD, setChooseTD ] = useState([]);
    let [ chooseBrands, setChooseBrands ] = useState([]);
    let [ priceNetto, setPriceNetto ] = useState("");
    let [ langsProduct, setLangsProduct ] = useState("");
    let [ city, setCity ] = useState("");
    let [ galleryRealization, setGalleryRealization ] = useState([]);
    let [ sneakpeakImage, setSneakpeakImage ] = useState(false);
    let [ smallHeader, setSmallHeader ] = useState("");
    let [ threeDIcon, setThreeDIcon ] = useState("false");
    let [ iconDescData, setIconDescData ] = useState([]);
    let [ marketingDescription, setMarketingDescription ] = useState("");
    let [ marketingDescriptionAfterPrice, setMarketingDescriptionAfterPrice ] = useState("");
    let [ galleryMT, setGalleryMT ] = useState([]);
    let [ products, setProducts ] = useState([]);
    let [ colorOfProducts, setColorOfProducts ] = useState([]);
    let [ colorOfTechnicalDesc, setColorOfTechnicalDesc ] = useState([]);
    let [ url, setUrl ] = useState("");
    let [ colors, setColors ] = useState([]);
    let [ chooseColors, setChooseColors ] = useState([])
    let [ filesDownload, setFilesDownload ] = useState([{
            file: '',
            category: '',
        }]);
    let [ ctItems, setCtItems ] = useState(0);
    let [ unit, setUnit ] = useState("");
    let [ info, setInfo ] = useState("");
    let relation = Get('relation');
    let langIDInUrl = Get('lang');
    let [ accessoriesValue, setAccessoriesValue ] = useState(false);

    useEffect(() => {
        if(!load && language && postType) {
            // setLangID(language.id)
            setLangID(langIDInUrl ? langIDInUrl : language.id)
            if(relation) {
                getRelationData()
            }
            setLoad(true);
        }
    }, [load, language, postType])

    const getRelationData = async() => {
        axios({
            method: 'post',
            url: `${ApiUrl()}/getPageByID`,
            data: {
                token: localStorage.getItem('token'),
                pageID: relation,
                langID: langIDInUrl,
            }
        }).then(response => {
            let res = JSON.parse(response.request.response).data[0];
            setTitle(res.title);
            setSneakpeak(res.sneak_peak);
            setResultPage(res);
            setDisableTopDate(String(res.disable_top));
            setImportant(String(res.important));
            setStatus(res.status);
            setSeoDescription(res.seo_description);
            setDimensions(res.dimensions);
            setLine(res.line);
            setWidth(res.width);
            setHeight(res.height);
            setDepth(res.depth);
            setPriceNetto(res.priceNetto);
            setLangsProduct(res.langsProduct);
            // setTechnicalDescription(res.technicalDescription);
            // setColorOfProducts(res.colorOfProducts);
            if(res.coloursTechnicalDesc) {
                setColoursTechnicalDesc(res.coloursTechnicalDesc);
            }
            if(res.date_range_from && (res.date_range_from).length > 0 && res.date_range_to && (res.date_range_to).length > 0) {
                setDateRange([new Date(res.date_range_from), new Date(res.date_range_to)]);
            }
            if(res.preview_image) {
                setPreviewImage(res.preview_image);
            }
            if(res.collectionDesc) {
                setCollectionDesc(res.collectionDesc);
            }
            if(res.chooseTD) {
                setChooseTD(res.chooseTD);
            }
            // if(res.chooseBrands) {
            //     setChooseBrands(res.chooseBrands);
            // }
            if(res.city) {
                setCity(res.city);
            }
            if(res.galleryRealization) {
                setGalleryRealization(res.galleryRealization);
            }
            if(res.sneak_peak_image) {
                setSneakpeakImage(res.sneak_peak_image);
            }
            if(res.smallHeader) {
                setSmallHeader(res.smallHeader);
            }
            if(res.threeDIcon) {
                setThreeDIcon(res.threeDIcon);
            }
            if(res.iconDescData) {
                setIconDescData(res.iconDescData);
            }
            if(res.marketingDescription) {
                setMarketingDescription(res.marketingDescription);
            }
            if(res.filesDownload) {
                setFilesDownload(res.filesDownload);
            }
            if(res.marketingDescriptionAfterPrice) {
                setMarketingDescriptionAfterPrice(res.marketingDescriptionAfterPrice);
            }
            if(res.galleryMT) {
                setGalleryMT(res.galleryMT);
            }
            if(res.url) {
                setUrl(res.url);
            }
            if(res.chooseColors) {
                setChooseColors(res.chooseColors);
            }
            if(res.unit) {
                setUnit(res.unit);
            }
            if(res.ctItems) {
                setCtItems(res.ctItems);
            }
            if(res.length) {
                setLength(res.length);
            }
            if(res.info) {
                setInfo(res.info);
            }
            if(res.accessoriesValue) {
                setAccessoriesValue(res.accessoriesValue);
            }
            if(res.sections && (res.sections).length > 0) {
                let dataSections = res.sections;
                if(dataSections && dataSections.length > 0) {
                    let relationSections = FilterRelationSections(dataSections); 
                    setSections(relationSections)
                }else{
                    setSections([])
                }
            }else{
                setSections([])
            }
            setResetLoad(generateRandomNumber(1, 99999999));
            setBlockedBtn(false);
        })
    }

    let validateForm = async() => {
        await setValidateSections(generateRandomNumber(1, 99999999)) // run validate sections
        await setForceValidate(true);
        await wait(300);
        await setForceValidate(false);
        let errors = await checkErrors();
        if ( !errors ) {
            await save();
        } 
    }

    async function wait(ms) {
        return new Promise(resolve => {
          setTimeout(resolve, ms);
        });
    }

    let checkErrors = () => {
        let result;
        let elements = document.querySelectorAll('.sections-component .error');
        let elementsCard = document.querySelectorAll('.new-page-container .error');

        if( elements.length > 0 ) {
            elements[0].closest('.content').classList.add("active");
            readTextNvda("sections");
            let topElement = elements[0].getBoundingClientRect().top;
            let windowScroll = window.scrollY;
            window.scroll({
                behavior: 'smooth',
                left: 0,
                top: windowScroll + topElement
              });
            result = true;
        } else if(elementsCard.length > 0) {
            readTextNvda('new-page');
            let topElement = elementsCard[0].getBoundingClientRect().top;
            let windowScroll = window.scrollY;
            window.scroll({
                behavior: 'smooth',
                left: 0,
                top: windowScroll + topElement
              });
            result = true;
        } else {
            result = false;
        }
        return result;
    }

    const generateRandomNumber = (min, max) => {
        return Math.floor(Math.random() * (max - min) + min);
    };

    const save = () => {
        setBlockedBtn(true);
        setBtnClickedNumber(btnClickedNumber + 1)
        let sendSections = filterSectionsData(sections)
        // iconDescData = filterData(iconDescData, "iconDescData");
        // filesDownload = filterData(filesDownload, "filesDownload");
        // galleryMT = filterData(galleryMT, "galleryMT");
        // if(colorOfProducts && postType == "colors") {
        //     colorOfProducts = filterData(colorOfProducts, "colorOfProducts");
        // }
        if(filesDownload && postType == "marketing-support") {
            // filesDownload = filterData(filesDownload, "filesDownload");
            filesDownload = false;
            galleryMT = filterData(galleryMT, "galleryMT");
        }else{
            filesDownload = false;
            galleryMT = false;
        }
        if(colorOfProducts && postType == "colors") {
            colorOfProducts = filterData(colorOfProducts, "colorOfProducts");
            iconDescData = filterData(iconDescData, "iconDescData");
        }else {
            colorOfProducts = false;
            iconDescData = false;
        }
        if(colorOfTechnicalDesc && postType == "colors-technical-description") {
            colorOfTechnicalDesc = filterData(colorOfTechnicalDesc, "colorOfTechnicalDesc");
        }else {
            colorOfTechnicalDesc = false;
        }
        axios({
            method: 'post',
            url: `${ApiUrl()}/savePage`,
            data: {
                token: localStorage.getItem('token'),
                title: title,
                sneakpeak: sneakpeak,
                sections: sendSections,
                userID: localStorage.getItem('userId'),
                postType: postType,
                relation: relation,
                language: langIDInUrl ? langIDInUrl : langID,
                categoriesItems: categoriesArray,
                dateRange: dateRange,
                previewImage: previewImage ? previewImage.id : false,
                disableTop: disableTopDate,
                important: important,
                status: status,
                seoDescription: seoDescription,
                dimensions, dimensions,
                line: line,
                width: width,
                height: height,
                depth: depth,
                length: length,
                coloursTechnicalDesc: coloursTechnicalDesc,
                technicalDescription: technicalDescription,
                collectionDesc: collectionDesc,
                chooseTD: chooseTD,
                chooseBrands: chooseBrands,
                priceNetto: priceNetto,
                langsProduct: langsProduct,
                city: city,
                galleryRealization: galleryRealization,
                sneakpeakImage: (sneakpeakImage ? sneakpeakImage.id : false),
                smallHeader: smallHeader,
                threeDIcon: threeDIcon,
                iconDescData, iconDescData,
                marketingDescription: marketingDescription,
                filesDownload: filesDownload,
                marketingDescriptionAfterPrice: marketingDescriptionAfterPrice,
                galleryMT: galleryMT,
                colorOfProducts: colorOfProducts,
                colorOfTechnicalDesc: colorOfTechnicalDesc,
                chooseColors: chooseColors,
                url: url,
                unit: unit,
                ctItems: ctItems,
                info: info,
                accessoriesValue: accessoriesValue,
                edit: false
            }
        }).then(response => {
            let res = JSON.parse(response.request.response);
            if(res.status = "success") {
                setBlockedBtn(false);
                incrementCountData()
                props.history.push(`/dashboard/${postType}/page/${res.pageID}`)
            }else{
                setBlockedBtn(false);
                dispatch({ type: 'NOTIFICATION_ALERT_TEXT', value: `<p>Coś poszło nie tak, prosimy spróbować ponownie później</p>` }) 
            }
        })
    }

    const incrementCountData = () => {
        let count = countData[postType];
        let newCountData = countData;
        newCountData[postType] = ++count;
        dispatch({ type: 'COUNT_DATA', value: newCountData })
    }

    const insertCategories = (data) => {
        let newCategoryArray = [];
        data && data.map(item => {
            newCategoryArray = [...newCategoryArray, item];
        })
        setCategoriesArray(newCategoryArray);
    }

    const threeDOptions = [
        {
            value: "false",
            text: "nie pokazuj"
        },
        {
            value: "true",
            text: "pokaż"
        },
    ]

    const unitOptions = [
        {
            value: "m",
            text: "m2",
        },
        {
            value: "szt.",
            text: "szt."
        }
    ]

    let keyPressed = (e, action) => {
        if ( e.code === "Enter" ) {
            action();
        }
    }

    const removeItemColour = (id) => {
        let newData = coloursTechnicalDesc.filter((item, i) => item.image.id === id ? false : item);
        setColoursTechnicalDesc(newData);
    }

    const removeItemGalleryMT = (id) => {
        let newData = galleryMT.filter((item, i) => item.image.id === id ? false : item);
        setGalleryMT(newData);
    }

    const addItemColour = (item) => {
        let newData = [...coloursTechnicalDesc, {
            image: item,
        }]
        setColoursTechnicalDesc(newData);
    }

    const addItemGalleryMT = (item) => {
        let newData = [...galleryMT, {
            image: item,
        }]
        setGalleryMT(newData);
    }

    const getPages = (postType) => {
        axios({
            method: 'get',
            url: `${ApiUrl()}/pagesList?langID=${langIDInUrl ? langIDInUrl : language.id}&postType=${postType}`,
            headers: { Authorization: `Bearer ${ localStorage.getItem('token') }` }
        }).then(response => {
            let res = JSON.parse(response.request.response);
            if(postType == "technical-description") {
                setTD(res.data);
            }else if(postType == "brands") {
                setBrandsList(res.data)
            }else if(postType == "products") {
                setProducts(res.data);
            }
        })
    }

    const getDataCatChildrens = (items) => {
        let newData = [{value: 0, text: "Nie wybrano"}];
        items.map((item, index) => {
            if(item.language == langID) {
                newData = [...newData, {
                    value: item.id,
                    text: item.name
                }]
            }
        })
        return newData;
    }

    return (
        <div className="container new-page-container">
            <PageTitle title={"Utwórz"} subTitle="Uzupełnij dane" />
            <div className="wrapper">
                <div className="form">
                    <div className="input-row">
                        <Input  name="title"
                            required={ true }
                            label="Tytuł strony"
                            type="text" 
                            value={ title }
                            disabled={ false } 
                            setFunction={ (val) => setTitle(val) } 
                            forceValidate={ forceValidate }
                            class="btn-primary btn-simple" />
                    </div>
                    {(postType == "brands" || postType == "human-touch") &&
                    <>
                        <div className="input-row">
                            <Editor required={true} forceValidate={ forceValidate } setFunction={ val => setSneakpeak(val) } value={ sneakpeak } label="Zajawka na liste" />
                        </div>
                        <div className="input-row">
                            <MediaLoad required={true} forceValidate={ forceValidate } setFunction={ val => setSneakpeakImage(val) } value={ sneakpeakImage } label="Zdjęcie zajawka na liste" />
                        </div>
                    </>
                    }
                    {postType == "brands" &&
                        <div className="input-row">
                            <Input  name="url"
                                required={ true }
                                label="Link do strony zewnętrznej"
                                type="text" 
                                value={ url }
                                disabled={ false } 
                                setFunction={ (val) => setUrl(val) } 
                                forceValidate={ forceValidate }
                                class="btn-primary btn-simple" />
                        </div>
                    }
                    {postType !== "realizations" && postType !== "accessories" && postType !== "collections" && postType !== "marketing-support" &&
                        <div className="input-row">
                            <Textarea  name="seo_description"
                                label="SEO - opis"
                                value={ seoDescription }
                                setFunction={ (val) => setSeoDescription(val) } 
                                class="btn-primary btn-simple" />
                        </div>
                    }
                    {postType == "realizations" &&
                        <>
                            <div className="input-row">
                                <Input  name="city"
                                    required={ true }
                                    label="Miasto"
                                    type="text" 
                                    value={ city }
                                    disabled={ false } 
                                    setFunction={ (val) => setCity(val) } 
                                    forceValidate={ forceValidate }
                                    class="btn-primary btn-simple" />
                            </div>
                            <div className="input-row">
                                <Gallery 
                                    value={ galleryRealization }
                                    setFunction={ (val) => setGalleryRealization(val) }
                                    forceValidate={ forceValidate }
                                />
                            </div>
                        </>
                    }
                    {postType == "marketing-support" &&
                        <>
                            <div className="input-row">
                                <Input  name="width"
                                    required={ true }
                                    label="Szerokość w cm"
                                    type="text" 
                                    value={ width }
                                    disabled={ false } 
                                    setFunction={ (val) => setWidth(val) } 
                                    forceValidate={ forceValidate }
                                    class="btn-primary btn-simple" />
                            </div>
                            <div className="input-row">
                                <Input  name="height"
                                    required={ true }
                                    label="Wysokość w cm"
                                    type="text" 
                                    value={ height }
                                    disabled={ false } 
                                    setFunction={ (val) => setHeight(val) } 
                                    forceValidate={ forceValidate }
                                    class="btn-primary btn-simple" />
                            </div>
                            <div className="input-row">
                                <Input  name="depth"
                                    required={ true }
                                    label="Grubość w cm"
                                    type="text" 
                                    value={ depth }
                                    disabled={ false } 
                                    setFunction={ (val) => setDepth(val) } 
                                    forceValidate={ forceValidate }
                                    class="btn-primary btn-simple" />
                            </div>
                            {/* <div className="input-row">
                                <Input  name="index"
                                    required={ true }
                                    label="Index"
                                    type="number" 
                                    value={ indexProduct }
                                    disabled={ false } 
                                    setFunction={ (val) => setIndexProduct(val) } 
                                    forceValidate={ forceValidate }
                                    class="btn-primary btn-simple" />
                            </div> */}
                            <div className="input-row">
                                <Input  name="price-netto"
                                    required={ true }
                                    label="Cena Netto"
                                    // type="" 
                                    value={ priceNetto }
                                    disabled={ false } 
                                    setFunction={ (val) => setPriceNetto(val) } 
                                    forceValidate={ forceValidate }
                                    class="btn-primary btn-simple" />
                            </div>
                            <div className="input-row">
                                <Input  name="langs"
                                    required={ true }
                                    label="Języki (zapisywać po średniku pl;en)"
                                    type="text" 
                                    value={ langsProduct }
                                    disabled={ false } 
                                    setFunction={ (val) => setLangsProduct(val) } 
                                    forceValidate={ forceValidate }
                                    class="btn-primary btn-simple" />
                            </div>
                            <div className="input-row">
                                <Editor required={false} forceValidate={ forceValidate } setFunction={ val => setMarketingDescription(val) } value={ marketingDescription } label="Opis" />
                            </div>
                            <div className="input-row">
                                <Editor required={false} forceValidate={ forceValidate } setFunction={ val => setMarketingDescriptionAfterPrice(val) } value={ marketingDescriptionAfterPrice } label="Opis po cenie" />
                            </div>
                            <div className="input-row">
                                <FilesDownload 
                                    data={ filesDownload }
                                    setFunction={ (val) => setFilesDownload(val) }
                                    forceValidate={ forceValidate } />
                            </div>
                            <div className="input-row">
                                <MediaLoad setFunction={ val => addItemGalleryMT(val)} value={ galleryMT } only="image" type="gallery" items={ galleryMT } removeItem={(id) => removeItemGalleryMT(id)} label="Galeria" />
                            </div>
                        </>
                    }
                    {postType == "collections" &&
                    <>
                        <div className="input-row">
                            <Textarea  name="collection_desc"
                                label="Opis kolekcji"
                                value={ collectionDesc }
                                setFunction={ (val) => setCollectionDesc(val) } 
                                class="btn-primary btn-simple" />
                        </div>
                        {langID &&
                            <>
                                <div className="select-boxes">
                                    <SelectItems
                                        label="Opisy techniczne"
                                        value={ chooseTD }
                                        langID={langID}
                                        handle={ (val) => setChooseTD(val) } 
                                        column="xs-12" />
                                </div>
                                <div className="select-boxes">
                                    <SelectItems
                                        label="Kolory"
                                        value={ chooseColors }
                                        langID={langID}
                                        handle={ (val) => setChooseColors(val) } 
                                        forceValidate={ forceValidate } 
                                        column="xs-12" />
                                </div>
                            </>
                        }
                        <div className="input-row categories-select">
                            {categories && categories.length > 0 && categories.map((cat, index) => {
                                if(cat.name == "Kategorie akcesorii") {
                                    return (
                                        <Select name="accessories"
                                            label="Wybierz grupe akcesoriów do załadowania"
                                            data={ getDataCatChildrens(cat.childrens) } 
                                            value={ accessoriesValue }
                                            handle={ (val) => setAccessoriesValue(val) } 
                                            class="btn-primary btn-simple" />
                                    )
                                }
                            })}
                        </div>
                        {/* <div className="input-row">
                            <MediaLoad setFunction={ val => addItemColour(val)} value={ coloursTechnicalDesc } only="image" type="gallery" items={ coloursTechnicalDesc } removeItem={(id) => removeItemColour(id)} label="Kolory" />
                        </div> */}
                    </>
                    }
                    {postType == "products" &&
                        <>
                            {langID &&
                                <div className="select-boxes">
                                    <SelectItems
                                        label="Marka"
                                        limit={1}
                                        langID={langID}
                                        value={ chooseBrands }
                                        handle={ (val) => setChooseBrands(val) } 
                                        forceValidate={ forceValidate } 
                                        column="xs-12" />
                                </div>
                            }
                        </>
                    }
                    {postType == "accessories" &&
                        <>
                            <div className="input-row">
                                <Input  name="dimensions"
                                    required={ true }
                                    label="Wymiary"
                                    type="text" 
                                    value={ dimensions }
                                    disabled={ false } 
                                    setFunction={ (val) => setDimensions(val) } 
                                    forceValidate={ forceValidate }
                                    class="btn-primary btn-simple" />
                            </div>
                            <div className="input-row">
                                <Textarea  name="info"
                                    label="Informacja"
                                    value={ info }
                                    setFunction={ (val) => setInfo(val) } 
                                    class="btn-primary btn-simple" />
                            </div>
                        </>
                    }
                    {postType == "technical-description" &&
                        <>
                            <div className="input-row">
                                <Input  name="line"
                                    required={ true }
                                    label="Line"
                                    type="text" 
                                    value={ line }
                                    disabled={ false } 
                                    setFunction={ (val) => setLine(val) } 
                                    forceValidate={ forceValidate }
                                    class="btn-primary btn-simple" />
                            </div>
                            <div className="input-row">
                                <Input  name="width"
                                    required={ false }
                                    label="Szerokość w cm"
                                    type="text" 
                                    value={ width }
                                    disabled={ false } 
                                    setFunction={ (val) => setWidth(val) } 
                                    forceValidate={ forceValidate }
                                    class="btn-primary btn-simple" />
                            </div>
                            <div className="input-row">
                                <Input  name="depth"
                                    required={ false }
                                    label="Grubość w cm"
                                    type="text" 
                                    value={ depth }
                                    disabled={ false } 
                                    setFunction={ (val) => setDepth(val) } 
                                    forceValidate={ forceValidate }
                                    class="btn-primary btn-simple" />
                            </div>
                            <div className="input-row">
                                <Input  name="height"
                                    required={ false }
                                    label="Długość w cm"
                                    type="text" 
                                    value={ height }
                                    disabled={ false } 
                                    setFunction={ (val) => setHeight(val) } 
                                    forceValidate={ forceValidate }
                                    class="btn-primary btn-simple" />
                            </div>
                            <div className="input-row">
                                <Input  name="ct-items"
                                    required={ false }
                                    label="Ilość w opakowaniu"
                                    type="number" 
                                    min="0"
                                    value={ ctItems }
                                    disabled={ false } 
                                    setFunction={ (val) => setCtItems(val) } 
                                    forceValidate={ forceValidate }
                                    class="btn-primary btn-simple" />
                            </div>
                            <div className="input-row">
                                <Select name="unit"
                                    label="Jednostka (Ilość w opakowaniu)"
                                    data={ unitOptions } 
                                    value={ String(unit) }
                                    handle={ (val) => setUnit(val) } 
                                    class="btn-primary btn-simple" />
                            </div>
                            <div className="input-row">
                                <Editor required={false} forceValidate={ forceValidate } setFunction={ val => setTechnicalDescription(val) } value={ technicalDescription } label="Opis niestandardowy" />
                            </div>
                            {/* <div className="input-row">
                                <MediaLoad setFunction={ val => addItemColour(val)} value={ coloursTechnicalDesc } only="image" type="gallery" items={ coloursTechnicalDesc } removeItem={(id) => removeItemColour(id)} label="Kolory" />
                            </div> */}
                        </>
                    }
                    {postType == "colors" && 
                        <>
                            <div className="input-row">
                                <Input  name="smallHeader"
                                    required={ false }
                                    label="Mały nagłówek nad tytułem"
                                    type="text" 
                                    value={ smallHeader }
                                    disabled={ false } 
                                    setFunction={ (val) => setSmallHeader(val) } 
                                    forceValidate={ forceValidate }
                                    class="btn-primary btn-simple" />
                            </div>
                            <div className="input-row">
                                <Input  name="width"
                                    required={ false }
                                    label="Szerokość w cm"
                                    type="text" 
                                    value={ width }
                                    disabled={ false } 
                                    setFunction={ (val) => setWidth(val) } 
                                    forceValidate={ forceValidate }
                                    class="btn-primary btn-simple" />
                            </div>
                            <div className="input-row">
                                <Input  name="height"
                                    required={ false }
                                    label="Wysokość w cm"
                                    type="text" 
                                    value={ height }
                                    disabled={ false } 
                                    setFunction={ (val) => setHeight(val) } 
                                    forceValidate={ forceValidate }
                                    class="btn-primary btn-simple" />
                            </div>
                            <div className="input-row">
                                <Input  name="length"
                                    required={ false }
                                    label="Długość w cm"
                                    type="text" 
                                    value={ length }
                                    disabled={ false } 
                                    setFunction={ (val) => setLength(val) } 
                                    forceValidate={ forceValidate }
                                    class="btn-primary btn-simple" />
                            </div>
                            <div className="input-row">
                                <Input  name="depth"
                                    required={ false }
                                    label="Grubość w cm"
                                    type="text" 
                                    value={ depth }
                                    disabled={ false } 
                                    setFunction={ (val) => setDepth(val) } 
                                    forceValidate={ forceValidate }
                                    class="btn-primary btn-simple" />
                            </div>
                            <div className="input-row">
                                <Select name="icon3d"
                                    label="Ikona 3D"
                                    data={ threeDOptions } 
                                    value={ String(threeDIcon) }
                                    handle={ (val) => setThreeDIcon(val) } 
                                    class="btn-primary btn-simple" />
                            </div>
                            <div className="input-row">
                                <IconDesc 
                                    data={ iconDescData }
                                    setFunction={ (val) => setIconDescData(val) }
                                    forceValidate={ forceValidate }
                                />
                            </div>
                            <div className="input-row">
                            {langID &&
                                <div className="row-section">
                                    <SelectItems
                                        label="Przypisz kolor do"
                                        langID={langID}
                                        value={ colorOfProducts }
                                        handle={ (val) => setColorOfProducts(val) } 
                                        forceValidate={ forceValidate } 
                                        // filter={true}
                                        column="xs-12" />
                                </div>
                            }
                            </div>
                        </>
                    }
                    {postType == "colors-technical-description" && 
                        <>
                            <div className="input-row">
                            {langID &&
                                <div className="row-section">
                                    <SelectItems
                                        label="Przypisz kolor do"
                                        langID={langID}
                                        value={ colorOfTechnicalDesc }
                                        handle={ (val) => setColorOfTechnicalDesc(val) } 
                                        forceValidate={ forceValidate } 
                                        // filter={true}
                                        column="xs-12" />
                                </div>
                            }
                            </div>
                        </>
                    }
                    <div className="input-row">
                    {langID &&
                        <Sections setSections={(data) => setSections(data)} sections={sections} resetLoad={resetLoad} validateSections={validateSections} edit={relation ? true : false} langID={langID} postType={postType} />
                    }
                    </div>
                    <div className="input-row save-row">
                        <Button title="Zapisz" setFunction={() => validateForm() } />
                    </div>
                </div>
                <div className="boxes">
                    {/* {postType == "articles" && (lastClickedRadioCategory.name == "Wydarzenia" || lastClickedRadioCategory.name == "Event") &&
                        <DateTimeRangerPicker date={dateRange} setFunction={(val) => setDateRange(val)} />
                    } */}
                    <StatusPage status={status} updateStatus={(val) => setStatus(val) } />
                    {postType == "accessories" && langID && categories && categories.length > 0 && categories.map((cat, index) => {
                        if(cat.name == "Kategorie akcesorii") {
                            return (
                                <Categories languagePageID={Get('lang') ? Get('lang') : langID} postType={postType} meta={resultPage && resultPage.meta} data={cat} index={index} insertCategories={(data) => insertCategories(data)} forceValidate={ validateSections } clickedItem={(item) => setLastClickedRadioCategory(item)} />
                            )
                        }
                    })}
                    {/* {postType == "products" && langID && categories && categories.length > 0 && categories.map((cat, index) => {
                        if(cat.name == "Kategorie") {
                            return (
                                <Categories languagePageID={Get('lang') ? Get('lang') : langID} postType={postType} meta={resultPage && resultPage.meta} data={cat} index={index} insertCategories={(data) => insertCategories(data)} forceValidate={ validateSections } clickedItem={(item) => setLastClickedRadioCategory(item)} />
                            )
                        }
                    })} */}
                    {postType == "marketing-support" && langID && categories && categories.length > 0 && categories.map((cat, index) => {
                        if(cat.name == "Kategorie marketing support") {
                            return (
                                <Categories languagePageID={Get('lang') ? Get('lang') : langID} postType={postType} meta={resultPage && resultPage.meta} data={cat} index={index} insertCategories={(data) => insertCategories(data)} forceValidate={ validateSections } clickedItem={(item) => setLastClickedRadioCategory(item)} />
                            )
                        }
                    })}

                    <ImagePost img={previewImage} setFunction={(val) => setPreviewImage(val)} forceValidate={ validateSections } />
                    <div className="btn-fix" tabIndex="0" aria-label="Zapisz strone" onClick={() => validateForm()} onKeyDown={(e) => keyPressed(e, validateForm)}>
                        <SaveIcon />
                    </div>
                </div>
            </div>
        </div>       
    );
}

export default NewPage;
